export function createValueRenderer({condition, renderValue, renderValueInput}) {
  return {
    renderValue(params) {
      return condition(params) ? renderValue.call(this, params) : null;
    },
    renderValueInput(params) {
      return condition(params) ? renderValueInput.call(this, params) : null;
    },
    renderValueWithoutCondition: renderValue,
    renderValueInputWithoutCondition: renderValueInput,
  };
}

export function updateValueRendererCondition({condition, valueRenderer}) {
  return createValueRenderer({
    condition,
    renderValue: valueRenderer.renderValueWithoutCondition,
    renderValueInput: valueRenderer.renderValueInputWithoutCondition,
  });
}

export default createValueRenderer;
