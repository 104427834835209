import {Component} from 'react';
import PropTypes from 'prop-types';
import {Accordion, Icon, Button} from 'semantic-ui-react';
import {
  observable,
  action,
  computed,
  toJS,
  reaction,
  comparer,
  autorun,
  makeObservable,
} from 'mobx';
import {observer} from 'mobx-react';
import {map, flatMap, transform, isEqual, isEmpty, isUndefined, isFinite, isString, isBoolean} from 'lodash';

import Value from './Value';
import FormFragment from './FormFragment';
import generatePropertyFromSchema from '../generatePropertyFromSchema';
import {isRangeFilter, isRegexFilter} from '../filterUtils';

import './SearchBox.less';

@observer
export default class SearchBox extends Component {
  static propTypes = {
    schema: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
    filters: PropTypes.object,
    errors: PropTypes.object,
    renderers: PropTypes.array,
    onChange: PropTypes.func.isRequired,
    valueProps: PropTypes.object,
    valueInputProps: PropTypes.object,
    applyOnChange: PropTypes.bool,
  };

  static defaultProps = {
    disabled: false,
    filters: {},
    errors: {},
    renderers: [],
    valueProps: {},
    valueInputProps: {},
  };

  @observable active = false;

  @computed get emptyFilters() {
    return transform(this.props.schema, (result, {name, schema}) => {
      result[name] = generatePropertyFromSchema(schema);
    }, {});
  }

  @computed get currentFilters() {
    const {filters} = this.props;
    return transform(this.props.schema, (result, {name}) => {
      if (!isUndefined(filters[name])) result[name] = filters[name];
    }, {});
  }

  filters = observable(this.emptyFilters);

  @computed get currentFiltersDescription() {
    const {currentFilters, props: {schema: schemaProp, renderers, valueProps}} = this;
    const currentFiltersAsText = transform(schemaProp, (result, {name, schema}) => {
      if (isBoolean(currentFilters[name]) || !isEmpty(currentFilters[name]) || isFinite(currentFilters[name])) {
        const isRange = isRangeFilter(currentFilters[name]);
        const isRegex = isRegexFilter(schemaProp, name);
        result.push(
          <span key={result.length}>
            <strong>{schema?.title ?? name}</strong>
            {isRange ? ' ' : isRegex ? ' ~= ' : ' = '}
            <Value
              name={name}
              value={currentFilters[name]}
              schema={schema}
              renderers={map(renderers, 'renderValue')}
              {...valueProps}
            />
          </span>
        );
      }
    }, []);
    if (currentFiltersAsText.length) {
      return flatMap(currentFiltersAsText, (chunk, index) => [index ? ' and ' : null, chunk]);
    } else {
      return 'All';
    }
  }

  @computed get newFilters() {
    const {filters, emptyFilters} = this;
    return transform(this.props.schema, (result, {name}) => {
      const filter = toJS(filters[name]);
      if (!isEqual(filter, emptyFilters[name])) result[name] = filter;
    }, {});
  }

  @action
  show = () => {
    this.active = true;
  };

  @action
  hide = () => {
    this.active = false;
  };

  @action
  toggle = () => {
    this.active = !this.active;
  };

  hideOnEscape = (e) => {
    if (e.key === 'Escape') {
      this.hide();
    }
  };

  toggleOnEnter = (e) => {
    if (e.key === 'Enter') {
      this.toggle();
    }
  };

  @action
  apply = () => {
    this.hide();
    this.applyFilters(this.newFilters);
  };

  @action
  clear = () => {
    this.hide();
    this.clearFilters();
    this.applyFilters({});
  };

  applyFilters(filters) {
    if (!isEqual(this.props.filters, filters)) {
      this.props.onChange(filters);
    }
  }

  @action
  clearFilters = () => {
    for (const {name} of this.props.schema) {
      this.filters[name] = this.emptyFilters[name];
    }
  };

  @action
  updateFilters = (filters) => {
    for (const {name} of this.props.schema) {
      this.filters[name] = isUndefined(filters[name]) ? this.emptyFilters[name] : filters[name];
    }
  };

  @action
  setFilterValue = (name, value) => {
    this.filters[name] = (isString(value) && value === '') ? this.emptyFilters[name] : value;
    if (this.props.applyOnChange) {
      this.applyFilters(this.newFilters);
    }
  };

  constructor(props = {}) {
    super(props);
    makeObservable(this);
    this.disposeFiltersUpdater = reaction(
      () => [this.props.filters, this.props.schema],
      ([filters]) => this.updateFilters(filters),
      {equals: comparer.structural, fireImmediately: true}
    );
    this.disposeShowOnError = autorun(() => {
      if (!this.props.disabled && !isEmpty(this.props.errors) && !this.active) {
        this.show();
      }
    });
  }

  componentWillUnmount() {
    this.disposeFiltersUpdater();
    this.disposeShowOnError();
  }

  render() {
    const {active, filters} = this;
    const {schema, disabled, errors, renderers, valueInputProps, applyOnChange} = this.props;

    return (
      <Accordion styled className='searchbox'>
        <Accordion.Title
          active={active}
          onClick={this.toggle}
          onKeyDown={this.toggleOnEnter}
          role='button'
          tabIndex={0}
        >
          <Icon name='dropdown' />
          {'Query: '}
          {this.currentFiltersDescription}
        </Accordion.Title>
        <Accordion.Content active={active}>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div className='ui form' onKeyDown={this.hideOnEscape} tabIndex={-1}>
            <FormFragment
              schema={schema}
              values={filters}
              errors={errors}
              disabled={disabled}
              renderers={map(renderers, 'renderValueInput')}
              onChange={this.setFilterValue}
              {...valueInputProps}
            />
            {!applyOnChange && <Button
              primary
              icon='search'
              content='Apply'
              disabled={disabled}
              onClick={this.apply}
            />}
            <Button
              className='ghost'
              icon='undo'
              content='Clear'
              disabled={disabled}
              onClick={this.clear}
            />
          </div>
        </Accordion.Content>
      </Accordion>
    );
  }
}
