import {Component} from 'react';
import PropTypes from 'prop-types';
import {Grid} from 'semantic-ui-react';
import {isEmpty, castArray, map, zipWith} from 'lodash';
import {observer} from 'mobx-react';
import {computed, makeObservable} from 'mobx';

import FetchDataError from './FetchDataError';
import Loader from './Loader';
import SearchBox from './SearchBox';
import Pagination from './Pagination';

import './DataFilteringLayout.less';

@observer
export default class DataFilteringLayout extends Component {
  static propTypes = {
    pagination: PropTypes.bool,
    updatePagination: PropTypes.func,
    filters: PropTypes.object,
    updateFilters: PropTypes.func,
    loaderVisible: PropTypes.bool,
    fetchDataError: PropTypes.instanceOf(Error),
    leftColumn: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
    rightColumn: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
    SearchBoxComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  };

  static defaultProps = {
    pagination: true,
    SearchBoxComponent: SearchBox
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed.struct get searchBoxErrors() {
    const {searchBoxErrors, fetchDataError} = this.props;
    if (searchBoxErrors) {
      return searchBoxErrors;
    }
    if (fetchDataError) {
      const {response, responseBody} = fetchDataError;
      if (response && response.status === 422 && responseBody && responseBody.errors) {
        return responseBody.errors;
      }
    }
    return {};
  }

  @computed get dataFilteringControls() {
    const {
      leftColumn, rightColumn, pagination, searchBoxSchema, filters, searchBoxErrors,
      searchBoxSchemaValueProps, searchBoxSchemaValueInputProps, loaderVisible, searchBoxRenderers,
      updateFilters, SearchBoxComponent, updatePagination, activePage, pageSize, pageSizes, totalCount,
    } = this.props;
    const paginationProps = {activePage, pageSize, pageSizes, totalCount, onChange: updatePagination};
    const addNodes = (array, column) => {
      if (column) array.push(...castArray(column));
    };
    const leftColumnNodes = searchBoxSchema ? [
      <SearchBoxComponent
        key='search-box'
        schema={searchBoxSchema}
        filters={filters}
        errors={searchBoxErrors}
        valueProps={searchBoxSchemaValueProps}
        valueInputProps={searchBoxSchemaValueInputProps}
        disabled={loaderVisible}
        renderers={searchBoxRenderers}
        onChange={updateFilters}
      />
    ] : [];
    const rightColumnNodes = pagination ? [
      <Pagination
        key='page-change-control'
        {...paginationProps}
        hasPageSizeControl={false}
      />,
      <Pagination
        key='page-size-control'
        {...paginationProps}
        sticky={false}
        hasPageChangeControl={false}
      />,
    ] : [];
    addNodes(leftColumnNodes, leftColumn);
    addNodes(rightColumnNodes, rightColumn);
    return zipWith(leftColumnNodes, rightColumnNodes, (leftColumn, rightColumn) => ({leftColumn, rightColumn}));
  }

  render() {
    const {searchBoxErrors, props: {children, loaderVisible, fetchDataError}} = this;
    return (
      <Grid stackable className='data-filtering-layout'>
        {map(this.dataFilteringControls, ({leftColumn, rightColumn}, index) => (
          <Grid.Row key={index} columns={2}>
            <Grid.Column verticalAlign='middle'>
              {leftColumn}
            </Grid.Column>
            <Grid.Column textAlign='right' verticalAlign='middle'>
              {rightColumn}
            </Grid.Column>
          </Grid.Row>
        ))}
        <Grid.Row>
          <Grid.Column>
            {loaderVisible ?
              <Loader />
            : !isEmpty(searchBoxErrors) ?
              null
            : fetchDataError ?
              <FetchDataError error={fetchDataError} />
            :
              children
            }
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
